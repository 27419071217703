export function setParam ({ paramName, paramValue, currentWindow = window } = {}) {
  const urlParams = setUrlParamAndDoNotApply({ paramName, paramValue, currentWindow });
  const baseUrl = currentWindow.location.href.split('?')[0];
  currentWindow.history.replaceState(null, null, `${baseUrl}?${urlParams.toString()}`);
}

export function setUrlParamAndDoNotApply ({ paramName, paramValue, currentWindow = window }, urlSearchParams = new URLSearchParams(currentWindow.location.search)) {
  urlSearchParams.set(paramName, paramValue);
  return urlSearchParams;
}

export function deleteUrlParam (name) {
  const urlSearchParams = deleteUrlParamAndDoNotApply({ name });
  history.replaceState(null, null, getUrl(urlSearchParams));
}

export function deleteUrlParamAndDoNotApply ({ name }, urlSearchParams = new URLSearchParams(window.location.search)) {
  urlSearchParams.delete(name);
  return urlSearchParams;
}

export function getUrlParam ({ name }, existingUrlSearchParams) {
  const urlSearchParams = existingUrlSearchParams || new URLSearchParams(location.search);
  return urlSearchParams.get(name);
}

export function getUrl (urlSearchParams) {
  const baseUrl = location.href.split('?')[0];
  return `${baseUrl}?${urlSearchParams}`;
}

export function getParametersStartingWith (searchParams, string) {
  const foundParameters = new Map();
  searchParams.forEach((value, key) => {
    if (key.startsWith(string)) {
      foundParameters.set(key, value);
    }
  });
  return foundParameters;
}

export function hasParameter (paramName) {
  const urlSearchParams = new URLSearchParams(location.search);
  return urlSearchParams.has(paramName);
}

/**
 * Parses the query part of the given url string and returns a key-value object with its parameters.
 * The value is an array, because a key can exists multiple times.
 *
 * @param {string} urlString
 * @return {Record<string, string[]>} Parameters as a { key: value } object
 */
export function getUrlParameters (urlString) {
  const [ , queryString ] = urlString.split('?');
  const params = {};
  const searchParams = new URLSearchParams(queryString);
  searchParams.forEach((value, key) => {
    const val = params[key];
    if (val === undefined) {
      params[key] = [ value ];
    } else {
      val.push(value);
    }
  });
  return params;
}

/**
 * Extend a given URL with the given parameters map as query parameters.
 *
 * Note: If the given URL already contains one or more parameters with the same name as one of the new
 * parameters specified, it or they will be replaced by the new value.
 *
 * @param {string} urlString - The URL to extend
 * @param {object} params - A map-like object of parameters top add to the URL
 * @return {string} - A string containing the original URL with the added query parameters
 */
export function extendUrlParameters (urlString, params = {}) {
  const [ baseUrl, queryString ] = urlString.split('?');
  const searchParams = new URLSearchParams(queryString);
  for (const [ paramName, paramValue ] of Object.entries(params)) {
    if (Array.isArray(paramValue)) {
      paramValue.forEach(value => searchParams.append(paramName, value));
    } else {
      searchParams.set(paramName, paramValue);
    }
  }
  const paramsUrlPart = searchParams.toString();
  return paramsUrlPart ? baseUrl + '?' + paramsUrlPart : baseUrl;
}

/**
 * Modify a given URL by removing the given parameters from the query string section, if present.
 *
 * @param {string} urlString - The URL to modify
 * @param {string|string[]} paramNames - The name(s) of the query parameter(s) to remove
 * @return {string} - A string containing the original URL minus the removed query parameters
 */
export function removeUrlParameters (urlString, paramNames) {
  if (!Array.isArray(paramNames)) {
    paramNames = [ paramNames ];
  }

  const [ baseUrl, queryString ] = urlString.split('?');
  const searchParams = new URLSearchParams(queryString);
  paramNames.forEach(paramName => searchParams.delete(paramName));
  const paramsUrlPart = searchParams.toString();
  return paramsUrlPart ? baseUrl + '?' + paramsUrlPart : baseUrl;
}

/**
 * Like getUrlParameters, but also converts boolean and numbers to their
 * corresponding JavaScript representation
 *
 * @param {string} urlString
 * @return {Record<string, any[]>} Parameters as a { key: value } object
 */
export function getParsedUrlParameters (urlString) {
  const parameters = getUrlParameters(urlString);

  for (const key of Object.keys(parameters)) {
    parameters[key] = parameters[key].map(val => {
      if (val === 'true') {
        return true;
      } else if (val === 'false') {
        return false;
      } else if (!isNaN(val)) {
        const valueAsFloat = parseFloat(val);
        if (!isNaN(valueAsFloat)) {
          return valueAsFloat;
        }
      } else {
        return val;
      }
    });
  }
  return parameters;
}

self.getParsedUrlParameters = getParsedUrlParameters;
