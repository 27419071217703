import { type BlockIdentifier, getBlockSettingsSingleton, isBlockIdentifier } from 'cadenza/map/block-settings';
import { isThemeFeature, type ThemeFeatureName } from 'cadenza/theming-api';
import { assert } from 'cadenza/utils/custom-error';
import type { FeatureName } from 'cadenza/model/feature-name';
import { isFeatureName } from 'cadenza/model/feature-name';

const UI_FEATURE_NAMES = [
  'workbook-designer', // allow showing designer
  'workbook-new-view', // show new view button
  'workbook-data-browser', // allow showing data browser
  'workbook-access-settings', // show access settings button
  'workbook-more-actions', // show more ... button in header
  'workbook-snapshots', // show buttons for creating and selecting snapshots
  'workbook-save', // show save/save and publish/save as
  'workbook-worksheet-menu', // show drop-down menu for worksheets
  'workbook-select-version', // allow selecting the version when clicking the versions tag
  'workbook-view-titlebar', // show the title bar of views
  'workbook-view-titlebar-buttons', // show the title bar buttons of views
  'workbook-analysis-context', // enable the analysis context
  'workbook-analysis-context-force-open' // always show the analysis context, do not allow closing
] as const;

export type UiFeatureName = typeof UI_FEATURE_NAMES[number];

type AllFeatures = FeatureName | UiFeatureName | ThemeFeatureName | BlockIdentifier;

interface FeatureTuple {
  feature?: FeatureName;
  uiFeature?: UiFeatureName;
  themeFeature?: ThemeFeatureName;
  block?: BlockIdentifier;
}

const FEATURE_SYNONYMS: FeatureTuple[] = [
  {
    themeFeature: 'classic-map-add-layer-shapefile',
    block: 'addShapefile'
  }
];

/**
 * Checks the availability of a Cadenza feature for the current user.
 *
 * The feature must be enabled on platform level and can be disabled on page, theme or request level.
 * Unfortunately, the feature names are different on each level, and we cannot change that, because
 * some are published. So we also check the synonyms of the given feature name.
 *
 * @param featureName - The name of the feature to check (on any level)
 * @return Whether the feature is available.
 */
export function isFeatureAvailable (featureName: AllFeatures) {
  const feature = getFeatureTuple(featureName);

  // Assertion, because this is called also from JS code.
  assert(Object.values(feature).some(v => v != null), `Unknown feature "${featureName}"`);

  return isFeatureAvailableInPlatform(feature)
    && isFeatureAvailableInPage(feature)
    && isFeatureAvailableInTheme(feature)
    && isFeatureAvailableInRequest(feature);
}

function getFeatureTuple (featureName: AllFeatures): FeatureTuple {
  return FEATURE_SYNONYMS.find(feature => Object.values(feature).includes(featureName)) ?? {
    feature: isFeatureName(featureName) ? featureName : undefined,
    uiFeature: isUiFeature(featureName) ? featureName : undefined,
    themeFeature: isThemeFeature(featureName) ? featureName : undefined,
    block: isBlockIdentifier(featureName) ? featureName : undefined
  };
}

function isUiFeature (featureName: string): featureName is UiFeatureName {
  return UI_FEATURE_NAMES.includes(featureName as never);
}

function isFeatureAvailableInRequest ({ block: blockIdentifier }: FeatureTuple) {
  const blockSettings = getBlockSettingsSingleton();
  return blockIdentifier === undefined
    || !blockSettings.isBlockSettingPresent(blockIdentifier)
    || blockSettings.isBlockEnabled(blockIdentifier);
}

function isFeatureAvailableInTheme ({ themeFeature: featureName }: FeatureTuple) {
  return featureName === undefined || window.Disy.theme.features[featureName] !== false;
}

function isFeatureAvailableInPage ({ uiFeature: featureName }: FeatureTuple) {
  return featureName === undefined || !window.Disy.disabledUiFeatures.includes(featureName);
}

function isFeatureAvailableInPlatform ({ feature: featureName }: FeatureTuple) {
  return featureName === undefined || (window.Disy.availableFeatures?.includes(featureName) ?? false);
}
