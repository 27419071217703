module.exports = {
  "ok": "OK",
  "apply": "Apply",
  "save": "Save",
  "back": "Back",
  "cancel": "Cancel",
  "clearInput": "Clear Input",
  "close": "Close",
  "finish": "Finish",
  "confirm.title": "Confirm",
  "contextHelp.title": "Help",
  "moreActions": "More functions ...",
  "defaultErrorMessage": "Please contact the application administrator.",
  "error": "Error",
  "execute": "Execute",
  "delete": "Delete",
  "dialog.close.title": "Close",
  "no": "No",
  "share": "Share",
  "warning": "Warning",
  "yes": "Yes",
  "continue": "Continue",
  "noMatchesFound": "No matches found",
  "noResult": "Your query didn't return any results. Please change the filter values.",
  "select2.selectionTooBig_one": "You can only select one item.",
  "select2.selectionTooBig_other": "You can only select {{count}} items.",
  "select2.placeholder": "Enter a search term",
  "error.validate.title": "Error Validating Input",
  "error.validate.message": "An error occurred while validating input.",
  "user.failedLoginCountWarning.message_one": "{{count}} failed login attempt since the last successful login.",
  "user.failedLoginCountWarning.message_other": "{{count}} failed login attempts since the last successful login.",
  "user.failedLoginCountWarning.title": "Security Information",
  "user.warnNearPasswordExpiration.message_one": "Your password expires in one day.",
  "user.warnNearPasswordExpiration.message_other": "Your password expires in {{count}} days.",
  "user.warnNearPasswordExpiration.title": "Security Information"
}